import React from "react";
import { rgba } from "polished";
import { Container, Row, Col } from "react-bootstrap";
import Link from 'gatsby-link'
import { Title, Section, Box, Span } from "../../components/Core";
import imgC1 from "../../assets/image/jpeg/anti.jpg";
import imgC2 from "../../assets/image/jpeg/fillers.jpg";
import imgC3 from "../../assets/image/jpeg/lips.jpg";
import imgC4 from "../../assets/image/jpeg/dfillers.jpg";
import imgC5 from "../../assets/image/jpeg/anti.jpg";
import imgC6 from "../../assets/image/jpeg/tear.jpg";
import imgC7 from "../../assets/image/jpeg/blog-prp.jpg";
import imgC8 from "../../assets/image/jpeg/chinjaw.jpg";
import imgC12 from "../../assets/image/jpeg/nose-hero.jpg";
import imgC13 from "../../assets/image/jpeg/eyebrow.jpg";
import imgC14 from "../../assets/image/jpeg/masseter.jpg";
import imgC15 from "../../assets/image/jpeg/gsmile.jpg";

import { device, breakpoints } from "../../utils";
import styled from 'styled-components';


const HeroT = styled.h2`
  letter-spacing: 0px;
  font-size: 18px;
  font-weight: 800;
  line-height: 20px;

  color: black;
  margin-bottom: 30px;

  @media ${device.sm} {
    font-size: 66px;
    line-height: 70px;
  }

  @media ${device.lg} {
    font-size: 30px;
    line-height: 40px;
  }

  @media ${device.xl} {
    font-size: 30px;
    line-height: 40px;
  }
`;



const ContentCard = ({
  color = "primary",
  className,
  iconName,
  title,
  children,
  ...rest
}) => (
  <Box
    bg="#fffbf6"
    border="1px solid"
    borderColor="border"
    p="20px"
    borderRadius={10}
    className={`d-flex align-items-center ${className}`}
    {...rest}
    css={`
      transition: all 0.3s ease-out;
      &:hover {
        box-shadow: ${({ theme }) => `0 52px 54px ${theme.colors.shadow};`};
      }
    `}
  >
    <Box
      size={55}
      minWidth={55}
      borderRadius="50%"
      color={color}
    
      fontSize="14px"
      className="d-flex justify-content-center align-items-center"
      css={`
        background: url(${iconName})  ;
        background-size:cover;
      `}
      mr={3}
    >
      
    </Box>

    <Title variant="card" fontSize="10px" mb={0}>
      {title}
    </Title>
  </Box>
);

const Treat = () => (
  <>
    <Section py={4} bg="#fff">
      <Container>
        <Row className="justify-content-center">
          <Col lg="9">
            <Box className="text-center" mb={[4, 5]}  data-aos="fade-up"
            data-aos-duration="750"
            data-aos-once="false"
            data-aos-delay="50"
            >
           
            </Box>
          </Col>
        </Row>
        <Row className="justify-content-center">


        <Col
            lg="4"
            xs="6"
            md="6"
            className="mb-4"
            data-aos="fade-up"
            data-aos-duration="750"
            data-aos-once="false"
            data-aos-delay="50"
          >
            <Link to="/anti-wrinkle-treatments"><ContentCard
              title="Anti-Wrinkle"
              color="ash"
              iconName={imgC1}
            /></Link>
          </Col>
       
        <Col
            lg="4"
            md="6"
            xs="6"
            className="mb-4"
            data-aos="fade-up"
            data-aos-duration="750"
            data-aos-once="false"
            data-aos-delay="50"
          >
            <Link to="/filler-packages"><ContentCard
              title="Filler Packages"
              color="ash"
              iconName={imgC2}
            /></Link>
          </Col>

          <Col
            lg="4"
            md="6"
            xs="6"
            className="mb-4"
            data-aos="fade-up"
            data-aos-duration="750"
            data-aos-once="false"
            data-aos-delay="50"
          >
           <Link to="/tear-trough-under-eye"><ContentCard
              title="Tear Trough (Under Eye)"
              color="primary"
              iconName={imgC6}
            /></Link> 
          </Col>

          <Col
            lg="4"
            md="6"
            xs="6"
            className="mb-4"
            data-aos="fade-up"
            data-aos-duration="750"
            data-aos-once="false"
            data-aos-delay="50"
          >
           <Link to="/non-surgical-nose-job"><ContentCard
              title="Nose Filler"
              color="primary"
              iconName={imgC12}
            /></Link> 
          </Col>
          <Col
            lg="4"
            md="6"
            xs="6"
            className="mb-4"
            data-aos="fade-up"
            data-aos-duration="750"
            data-aos-once="false"
            data-aos-delay="50"
          >
            <Link to="/lip-filler"><ContentCard
              title="Lip Enhancements"
              color="warning"
              iconName={imgC3}
            /></Link>
          </Col>
        
        
        
          <Col
            lg="4"
            md="6"
            xs="6"
            className="mb-4"
            data-aos="fade-up"
            data-aos-duration="750"
            data-aos-once="false"
            data-aos-delay="50"
          >
           <Link to="/chin-filler"> <ContentCard
              title="Chin Filler"
              color="ash"
              iconName={imgC8}
            /></Link>
          </Col>


          <Col
            lg="4"
            md="6"
            xs="6"
            className="mb-4"
            data-aos="fade-up"
            data-aos-duration="750"
            data-aos-once="false"
            data-aos-delay="50"
          >
           <Link to="/jaw-filler"> <ContentCard
              title="Jaw Filler"
              color="ash"
              iconName={imgC8}
            /></Link>
          </Col>
         
        
          <Col
            lg="4"
            md="6"
            xs="6"
            className="mb-4"
            data-aos="fade-up"
            data-aos-duration="750"
            data-aos-once="false"
            data-aos-delay="50"
          >
           <Link to="/cheek-augmentation"><ContentCard
              title="Cheek Augmentation"
              color="primary"
              iconName={imgC4}
            /></Link> 
          </Col>
   
          <Col
            lg="4"
            md="6"
            xs="6"
            className="mb-4"
            data-aos="fade-up"
            data-aos-duration="750"
            data-aos-once="false"
            data-aos-delay="50"
          >
           <Link to="/smile-line"><ContentCard
              title="Smile Lines"
              color="primary"
              iconName={imgC7}
            /></Link> 
          </Col>

          <Col
            lg="4"
            md="6"
            xs="6"
            className="mb-4"
            data-aos="fade-up"
            data-aos-duration="750"
            data-aos-once="false"
            data-aos-delay="50"
          >
           <Link to="/corner-of-mouth"><ContentCard
              title="Corner of mouth"
              color="primary"
              iconName={imgC5}
            /></Link> 
          </Col>


          <Col
            lg="4"
            md="6"
            xs="6"
            className="mb-4"
            data-aos="fade-up"
            data-aos-duration="750"
            data-aos-once="false"
            data-aos-delay="50"
          >
           <Link to="/eyebrow-lift"><ContentCard
              title="Eyebrow Lift"
              color="primary"
              iconName={imgC13}
            /></Link> 
          </Col>


          <Col
            lg="4"
            md="6"
            xs="6"
            className="mb-4"
            data-aos="fade-up"
            data-aos-duration="750"
            data-aos-once="false"
            data-aos-delay="50"
          >
           <Link to="/masseter"><ContentCard
              title="Masseter"
              color="primary"
              iconName={imgC14}
            /></Link> 
          </Col>


          <Col
            lg="4"
            md="6"
            xs="6"
            className="mb-4"
            data-aos="fade-up"
            data-aos-duration="750"
            data-aos-once="false"
            data-aos-delay="50"
          >
           <Link to="/gummy-smile"><ContentCard
              title="Gummy Smile"
              color="primary"
              iconName={imgC15}
            /></Link> 
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default Treat;
